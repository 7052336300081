<template>
    <div>
        <!-- Événements concernant l'utilisateur courant (organisateur | participant) -->
        <card-table-search v-if="ownEvenements.data.length" :items="ownEvenements.data"
                           :fields="ownEvenements.fields" :actions="ownEvenements.actions"
                           :custom-rendered="['nom', 'valide', 'role']"
                           title="Vos événements"
                           subtitle="Événements que vous organisez ou auxquels vous participez">
            <template #nom="{row}">
                <router-link :to="linkToDetail(row.item, $route)">
                    {{ row.item.nom }}
                </router-link>
            </template>
            <template #valide="{row}">
                <b-badge :variant="getBadgeColorStatut(row.item)">
                    {{ getTextStatut(row.item) }}
                </b-badge>
            </template>
            <template #role="{row}">
                <b-badge :variant="roleBadge(row.item)">
                    {{ roleText(row.item) }}
                </b-badge>
            </template>
        </card-table-search>

        <!-- Événements passés -->
        <card-table-search :items="previousEvenements.data"
                           :fields="previousEvenements.fields"
                           :custom-rendered="['nom']"
                           title="Événements passés"
                           :starts-open="false">
            <template #nom="{row}">
                <router-link :to="linkToDetail(row.item, $route)">
                    {{ row.item.nom }}
                </router-link>
            </template>
        </card-table-search>
        <cluji-card>
            <template #title>
                Événements du Cluji à venir
                <b-button pill variant="success" size="sm" class="ml-2" v-b-tooltip.hover="'Créer un événement'"
                          :to="{name: 'creation_evenement'}" v-if="isCluji()">
                    <font-awesome-icon icon="plus"/>
                </b-button>
            </template>
            <b-col xl="8" offset-xl="2" lg="10" offset-lg="1">
                <search-bar :objects="evenements" @filter="onFilter"
                            :fuse-options="{keys: ['nom', 'resume']}"
                            class="mt-3" v-if="evenements.length"/>
                <p v-else>Aucun événement actuellement</p>
            </b-col>
        </cluji-card>

        <!-- Événements à venir (sous forme jolie, du moins plus qu'une CardTableSearch) -->
        <evenement-card-preview :evenement="evenement"
                                v-for="evenement in displayEvenements"
                                :key="'event_' + evenement.id"/>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    import {apiPath}         from '@/util/http';
    import alert             from '@/util/alert';
    import demande           from '@/util/demande';
    import {isCluji, isUser} from '@/util/auth';
    import {linkToDetail}    from '@/util/evenement';
    import {format2Dates}    from '@/util/date';

    const CardTableSearch            = () => import('@/components/CardTableSearch');
    const SearchBar                  = () => import('@/components/SearchBar');
    const ClujiCard                  = () => import('@/components/ClujiCard');
    const EvenementCardPreview       = () => import('@/components/evenement/EvenementCardPreview');
    const DisplayInfosEvenementModal = () => import('@/components/modals/evenement/DisplayInfosEvenementModal');

    export default {
        name: 'FilEvenements',
        components: {CardTableSearch, SearchBar, ClujiCard, EvenementCardPreview},
        data() {
            return {
                ownEvenements: {
                    data: [],
                    fields: [
                        {
                            key: 'nom',
                            label: 'Nom',
                            sortable: true
                        },
                        {
                            key: 'dateDebut',
                            label: 'Dates',
                            sortable: true,
                            formatter: (value, key, item) => format2Dates(item.dateDebut, item.dateFin)
                        },
                        {
                            key: 'resume',
                            label: 'Résumé',
                            sortable: true
                        },
                        {
                            key: 'valide',
                            label: 'Statut',
                            sortable: true,
                            formatter: (value, key, item) => demande.getTextStatut(item)
                        },
                        {
                            key: 'role',
                            label: 'Rôle',
                            sortable: true,
                            formatter: (value, key, item) => this.roleText(item)
                        },
                        {
                            key: 'actions',
                            label: 'Actions'
                        }
                    ],
                    actions: [
                        {
                            key: 'action_display',
                            color: () => 'secondary',
                            icon: 'eye',
                            tooltip: 'Afficher',
                            handler: ({item}) => this.$store.dispatch('modal/create', {
                                component: DisplayInfosEvenementModal,
                                props: {evenement: item}
                            })
                        },
                        {
                            key: 'action_delete',
                            color: () => 'danger',
                            icon: 'trash',
                            tooltip: 'Supprimer',
                            handler: ({item}) => this.deleteEvenement(item),
                            display: ({item}) => !item.valide
                        }
                    ]
                },
                previousEvenements: {
                    data: [],
                    fields: [
                        {
                            key: 'nom',
                            label: 'Nom',
                            sortable: true
                        },
                        {
                            key: 'dateDebut',
                            label: 'Dates',
                            sortable: true,
                            formatter: (value, key, item) => format2Dates(item.dateDebut, item.dateFin)
                        },
                        {
                            key: 'resume',
                            label: 'Résumé',
                            sortable: true
                        }
                    ]
                },
                evenements: [],
                displayEvenements: [],
                fuseOptions: {
                    keys: ['nom', 'resume']
                }
            };
        },
        computed: {...mapState({user: 'user'})},
        methods: {
            isCluji,
            linkToDetail,
            ...demande,
            loadNextPublicEvents() {
                return this.axios.get(apiPath('list_next_evenements_quickview'))
                    .then(response => {
                        this.evenements        = demande.momentDates(response.data);
                        this.displayEvenements = this.evenements;
                    })
                    .catch(() => this.$toaster.error('Impossible de charger la liste des événements publics'));
            },
            loadPreviousPublicEvents() {
                return this.axios.get(apiPath('list_previous_evenements_quickview'))
                    .then(response => this.previousEvenements.data = demande.momentDates(response.data))
                    .catch(() => this.$toaster.error('Impossible de charger la liste des événements publics'));
            },
            loadOwnEvents() {
                return isUser()
                    ? this.axios.get(apiPath('list_own_evenements_quickview'))
                        .then(response => this.ownEvenements.data = demande.momentDates(response.data))
                        .catch(() => this.$toaster.error('Impossible de charger la liste de vos événements'))
                    : Promise.resolve();
            },
            loadData() {
                alert.loading();
                this.loadNextPublicEvents()
                    .then(() => this.loadOwnEvents())
                    .then(() => this.loadPreviousPublicEvents())
                    .finally(alert.stopLoading);
            },
            onFilter(val) {
                this.displayEvenements = val;
            },
            deleteEvenement(evenement) {
                alert.confirm(
                    'Supprimer l\'événement "' + evenement.nom + '" ?',
                    () => this.axios.delete(apiPath('delete_evenement', {evenement: evenement.id}))
                        .then(() => {
                            this.$toaster.success('Événement supprimé');
                            this.loadData();
                        })
                        .catch(() => this.$toaster.error('Impossible de supprimer l\'événement'))
                );
            },
            roleText(evenement) {
                return evenement.organisateur.id === this.user.utilisateur.id ? 'Organisateur' : 'Participant';
            },
            roleBadge(evenement) {
                return evenement.organisateur.id === this.user.utilisateur.id ? 'primary' : 'secondary';
            }
        },
        mounted() {
            this.loadData();
        }
    };
</script>

<style scoped>
    .badge-secondary {
        color: #75bbec;
    }
</style>